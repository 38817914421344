<template>
    <div>
        <!-- 空运询柜 AirTransport -->
        <div class="airTransport">
            <!-- 海运询柜 -->
            <div class="airTransport_Top">
                <div class="backgroundPicture"></div>
            </div>
        </div>
        <AirTransport_Component></AirTransport_Component>

        <Footer_Compontent></Footer_Compontent>
    </div>
</template>

<script>
import AirTransport_Component from '../../components/EnquiryCabinet/AirTransport_Component.vue'
import Footer_Compontent from '../../components/Footer_Compontent.vue'
export default {
    name: "AirTransport",
    components: { AirTransport_Component,  Footer_Compontent }

}
</script>

<style lang="scss" scoped>
.airTransport {
    width: 100%;

    .airTransport_Top {
        width: 100%;
        // height: 363px;

        .backgroundPicture {
            height: 158px; 
            background: url('../../assets/homePage/salesVolume.png');
        }

        // .inquiry {
        //     width: 1200px;
        //     // height: 265px;
        //     background: #FFFFFF;
        //     border-radius: 8px 8px 8px 8px;
        //     margin: 0 auto;
        //     border: 1px solid;
        // }
    }
}

.footer {
    margin-top: 133px;
}
</style>