<template>
    <div class="inquiry">
        <div class="inquiryTitle">空运询价</div>
        <div class="inquiry_Top">
            <div class="inquiry_Smale_1">
                <!-- 选择起运站 -->
                <div class="departure">
                    <div class="departure_Box_Right">
                        <el-select v-model="departureList" placeholder="选择起运站" v-el-select-loadmore="loadmore"
                            filterable :filter-method="remoteMethod" @focus="changeFous">
                            <el-option v-for="(item, index) in departureStationList" :key="index" :label="item.city"
                                :value="item.city">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- 选择目的站 destination -->
                <div class="destination">
                    <div class="destination_Box_Right">
                        <el-select v-model="destinationList" placeholder="选择目的站" v-el-select-loadmore="loadmore1"
                            filterable :filter-method="remoteMethod1" @focus="changeFous1">
                            <el-option v-for="(item, index) in destinationListList" :key="index" :label="item.city"
                                :value="item.city">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- 起运日范围 dayRange -->

                <div class="dayRange">
                    <el-date-picker v-model="dayRangeDate" type="daterange" start-placeholder="货好开始日期"
                        :picker-options="pickerOptions" end-placeholder="货好结束日期" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </div>

                <div class="weightS">
                    <div class="weight_Left"><span>件毛体</span></div>
                    <!-- 件数 -->
                    <div class="number_1s weightSInput ">
                        <input type="text" v-model="number_1s" placeholder="件数（plt）" @input="inputNum1">
                        <div class="num_Icon">
                            <i class="el-icon-caret-top" @click="number_1s_Jia"></i>
                            <i class="el-icon-caret-bottom" @click="number_1s_Jian"></i>
                        </div>
                        <div class="placeholderS" v-show="number_1s"><span>件数（plt）</span></div>
                    </div>
                    <!-- 毛重 -->
                    <div class="grossWeight weightSInput">
                        <input type="text" v-model="grossWeightNum" placeholder="毛重（kgs）" @input="inputNum2">
                        <div class="num_Icon">
                            <i class="el-icon-caret-top" @click="grossWeight_Jia"></i>
                            <i class="el-icon-caret-bottom" @click="grossWeight_Jian"></i>
                        </div>
                        <div class="placeholderS" v-show="grossWeightNum"><span>毛重（kgs）</span></div>
                    </div>
                    <!-- 体积 -->
                    <div class="volume weightSInput">
                        <input type="text" v-model="volumeNum" placeholder="体积（cbm）" @input="inputNum3">
                        <div class="num_Icon">
                            <i class="el-icon-caret-top" @click="volume_Jia"></i>
                            <i class="el-icon-caret-bottom" @click="volume_Jian"></i>
                        </div>
                        <div class="placeholderS" v-show="volumeNum"><span>体积（cbm）</span></div>
                    </div>
                </div>

            </div>

            <div class="inquiry_Smale_2">
                <!-- 柜子数量  numberOfCabinets -->
                <!-- <div class="numberOfCabinets">
                    <input type="text" v-model="cabinetNum" placeholder="柜子数量">
                    <div class="num_Icon">
                        <i class="el-icon-caret-top" @click="num_Jia"></i>
                        <i class="el-icon-caret-bottom" @click="num_Jian"></i>
                    </div>
                </div> -->
                <div class="dayRange">
                    <el-date-picker v-model="expectedTime" type="daterange" start-placeholder="期望到达时间"
                        :picker-options="pickerOptions" end-placeholder="期望到达时间" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </div>
                <!-- 贸易方式 modeOfTrade -->
                <div class="modeOfTrade">
                    <div class="modeOfTrade_Box_Right">
                        <el-select v-model="modeOfTradeList" placeholder="贸易方式">
                            <el-option value="FOB">FOB</el-option>
                            <el-option value="CIF">CIF</el-option>
                            <el-option value="CIP">CIP</el-option>
                            <el-option value="CFR">CFR</el-option>
                            <el-option value="DDP">DDP</el-option>
                            <el-option value="DDU">DDU</el-option>
                            <el-option value="EXW">EXW</el-option>
                            <el-option value="FCA">FCA</el-option>
                            <el-option value="CPT">CPT</el-option>
                            <el-option value="DAT">DAT</el-option>
                            <el-option value="DAP">DAP</el-option>
                            <el-option value="FAS">FAS</el-option>
                        </el-select>
                    </div>
                </div>



            </div>
            <div class="inquiry_Button">
                <div class="div1">
                    <div class="isdaomen">
                        <span>是否需要到门</span>
                        <label class="label_1">
                            <input type="radio" v-model="radio" value="N"> <span>否</span>
                        </label>
                        <label class="label_2">
                            <input type="radio" v-model="radio" value="Y"> <span>是</span>
                        </label>

                    </div>
                    <div v-show="radio == 'Y'" class="dizhi">
                        <input type="text" v-model="toDoorAddress" placeholder="请输入到门地址">
                    </div>
                </div>
                <div class="div2">
                    <div class="notes">
                        <el-input type="textarea" :rows="2" placeholder="备注" v-model="remarksValue">
                        </el-input>
                    </div>
                    <div @click="inquiry_Button" class="btns">
                        <i class="el-icon-search"></i>
                        <span>询价</span>
                    </div>
                </div>


            </div>
        </div>
        <div class="xunjia" v-show="handleXunJia">
            <div class="xunjiaBox">
                <div class="xjTop">
                    <div class="img">
                        <img src="../../assets/homePage/tijiao.png" alt="">
                        <div class="inquirys">
                            <p>询价成功</p>
                            <p>您的询价已受理，请等待业务员报价！</p>
                        </div>

                    </div>

                    <div class="xdDate">
                        <p>下单时间</p>
                        <p v-if="inquiryList.createTime">{{ inquiryList.createTime.split(' ')[0] }}</p>
                    </div>
                    <div class="orderNumber">
                        <p>订单号</p>
                        <p>{{ inquiryList.id }}</p>
                    </div>
                    <div class="orderType">
                        <p>订单类型</p>
                        <p>空运</p>
                    </div>
                    <div class="orderType">
                        <p>装箱类型</p>
                        <p>{{ inquiryList.packingtype }}</p>
                    </div>
                </div>
                <div class="tips">
                    <p>温馨提示：您的询价正在处理，请等待我们的报价，您可以在询价界面查看报价，也可以在个人中心-询价订单中查看！</p>
                </div>
                <div class="xjButtom">
                    <ul>
                        <li class="lis_1">
                            <div class="startingPoint">
                                <div class="radius_1">起</div>
                                <div class="radius_2"></div>
                                <div class="radius_3"></div>
                                <div class="radius_4"></div>
                                <div class="radius_5">到</div>
                            </div>
                            <div class="address">
                                <p>{{ inquiryList.departure }}</p>
                                <p>{{ inquiryList.destination }}</p>
                            </div>
                        </li>
                        <li class="lis_2">
                            <div>
                                <!-- <p>集装箱类型</p>
                                <p>{{ inquiryList.cupboard }}</p> -->
                                <p>货好时间</p>
                                <p v-if="inquiryList.pickUpTimeBeg">
                                    {{ inquiryList.pickUpTimeBeg.split(" ")[0] }}
                                    \ {{ inquiryList.pickUpTimeEnd.split(" ")[0] }}</p>
                            </div>
                            <div>
                                <p>到门地址</p>
                                <p>{{ inquiryList.finalAddr }}</p>
                            </div>
                        </li>
                        <li class="lis_2">
                            <div>
                                <!-- <p>贸易方式</p>
                                <p>{{ inquiryList.tradeMode }}</p> -->
                                <p>期望到达时间</p>
                                <p v-if="inquiryList.expectTimeBeg">
                                    {{ inquiryList.expectTimeBeg.split(" ")[0] }}\
                                    {{ inquiryList.expectTimeEnd.split(" ")[0] }}
                                </p>
                            </div>
                            <div>
                                <p>费用备注</p>
                                <p>请等待报价！</p>
                            </div>
                        </li>
                        <li class="lis_2">
                            <div>
                                <!-- <p>仓位类型</p>
                                <p>{{ inquiryList.shippingSpace }}</p> -->
                                <p>件数</p>
                                <p>{{ inquiryList.pkg }}</p>
                            </div>
                            <div>
                                <p>船 期</p>
                                <p>暂无</p>
                            </div>
                        </li>
                        <li class="lis_2">
                            <div>
                                <!-- <p>仓位类型</p>
                                <p>COC</p> -->
                                <p>毛重</p>
                                <p>{{ inquiryList.kgs }}</p>
                            </div>
                            <div>
                                <p>报价有效期</p>
                                <p v-if="inquiryList.endTime">
                                    {{ inquiryList.endTime.split(" ")[0] }}
                                </p>
                                <p v-else>暂无</p>
                            </div>
                        </li>
                        <li class="lis_2">
                            <div>
                                <!-- <p>仓位类型</p>
                                <p>COC</p> -->
                                <p>体积</p>
                                <p>{{ inquiryList.cbm }}</p>
                            </div>
                            <div>
                                <p>备注</p>
                                <p>{{ inquiryList.userNote || '暂无' }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="xjBth">
                    <div class="close" @click="handleClose">关闭</div>
                    <div class="see" @click="handleXunJia = false">查看询价</div>
                </div>
            </div>
        </div>
        <!-- route -->

        <div class="inquiry_ButtomS">
            <div class="placeAnOrder">
                <!-- orderNumber orderType tradeTerms stateS  haulageLane destinationLane containerType toDoorAddress totalPrice remarks -->
                <el-table :data="orderDetailList" style="width: 100%" border :header-row-style="{ height: '50px' }"
                    :row-style="{ height: '56px' }">
                    <el-table-column prop="id" label="订单号" width="200">
                    </el-table-column>
                    <el-table-column prop="cate" label="订单类型" width="150">
                    </el-table-column>
                    <el-table-column prop="tradeMode" label="贸易方式" width="150">
                    </el-table-column>
                    <el-table-column label="状态" width="120">
                        <template slot-scope="scope">
                            <p v-if="scope.row.state == 0">询价中</p>
                            <p v-if="scope.row.state == 1">已报价</p>
                            <p v-if="scope.row.state == 2">已下单</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="departure" label="起运站" width="120">
                    </el-table-column>
                    <el-table-column prop="destination" label="目的站" width="120">
                    </el-table-column>
                    <el-table-column label="件毛体" width="300">
                        <template slot-scope="scope">
                            <span>件数{{ scope.row.pkg }},毛重{{ scope.row.kgs }},体积,{{ scope.row.cbm }}</span>
                        </template>
                        <span>{{}}</span>
                    </el-table-column>
                    <el-table-column label="到门地址" width="120">
                        <template slot-scope="scope">
                            <p>{{ scope.row.finalAddr || '无' }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="总价" width="120">
                        <template slot-scope="scope">
                            <p>
                                <span>{{ scope.row.currency | getCurrency }}</span>
                                {{ scope.row.price ||
                                        '请等待报价'
                                }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="note" label="备注" width="120">
                    </el-table-column>
                    <el-table-column label="操作" width="240" :key="new Date().getTime()" fixed="right" class-name="nana">
                        <template slot-scope="scope">
                            <el-button class="placeAnOrderImmediately1" v-if="scope.row.state !== 1">等待报价</el-button>
                            <el-button v-else class="placeAnOrderImmediately" type="text"
                                @click.prevent="placeAnOrderButton(scope.row)" :disabled="scope.row.state !== 1">
                                <span>立即下单</span>
                            </el-button>
                            <el-button type="text" class="detailOrder" @click="handleDetailOrder(scope.row)">详情
                            </el-button>
                            <el-button type="text" class="cancelTheOrder" @click="cancelTheOrder(scope.row)">撤单
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination background layout="prev, pager, next" :total="paging.total"
                    @current-change="handleSizeChange" :page-size="paging.size">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { cityList, startCityList, endCityList } from '../../tools/ajax';
import { baseUrl } from '../../tools/baseUrl';
import { mapState } from 'vuex';
export default {
    name: "Inquiry",
    data() {
        return {
            inquiryList: [], //询价
            handleXunJia: false, //点击询价
            departureStationList: [], //起运站数据
            destinationListList: [], //目的站
            departureList: "",//起运巷
            destinationList: "",//目的巷
            // dayRangeList: "",//起运日范围
            dayRangeDate: "", //起运日范围
            expectedTime: "",//期望日期
            containerTypeList: "", //集装箱类型
            numberOfCabinetsList: "",//柜子数量
            modeOfTradeList: "",//贸易方式
            shippingSpaceList: [{ //仓位类型

            }],
            cabinetNum: "",//柜子数量
            toDoorAddress: "", //到门地址
            remarksValue: "",//备注
            number_1s: '',//件数
            grossWeightNum: '',//毛重
            volumeNum: '', //体积
            radio: 'N',



            formData: {
                pageNo: 1, //当前页
            },
            formData1: {
                pageNo: 1, //当前页

            },
            paging: {
                total: 0,
                size: 5
            },
            orderDetailList: [],//询价记录
            inquiryListPageNo: 1, //记录页
            states: -1,

            pickerOptions: {  // 对象
                // 禁用时间
                selectableRange: '10:00:00- 23:59:59', // 动态写法在初始化时 对象.的方式更改
                disabledDate: (time) => {  // 禁用日期
                    let nowData = new Date()
                    nowData = new Date(nowData.setDate(nowData.getDate() - 1))
                    return time < nowData
                }
            }

        }
    },
    created() {

        this.getstartCityList();//出发站
        this.getendCityList(); //目的站
        this.getinquiryList();

    },
    watch: {
        ...mapState(['token'])
    },
    methods: {
        getstartCityList() { //出发站
            startCityList().then((res) => {
                this.departureStationList = res.result.records || '';
            })
        },
        getendCityList() { //目的站
            endCityList().then((res) => {
                console.log(res);
                this.destinationListList = res.result.records || '';
            })
        },

        //#region  //限制输入文字
        inputNum1(e) {  //限制输入数字
            this.number_1s = e.target.value;
            this.number_1s = this.number_1s.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
            this.number_1s = this.number_1s.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
            this.number_1s = this.number_1s.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
            this.number_1s = this.number_1s.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
        },
        inputNum2(e) {
            this.grossWeightNum = e.target.value;
            this.grossWeightNum = this.grossWeightNum.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
            this.grossWeightNum = this.grossWeightNum.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
            this.grossWeightNum = this.grossWeightNum.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
            this.grossWeightNum = this.grossWeightNum.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
        },
        inputNum3(e) {
            this.volumeNum = e.target.value;
            this.volumeNum = this.volumeNum.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
            this.volumeNum = this.volumeNum.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
            this.volumeNum = this.volumeNum.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
            this.volumeNum = this.volumeNum.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
        },
        inputNum4(e) {
            this.cabinetNum = e.target.value;
            this.cabinetNum = this.cabinetNum.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
            this.cabinetNum = this.cabinetNum.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
            this.cabinetNum = this.cabinetNum.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
            this.cabinetNum = this.cabinetNum.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
        },
        //#endregio

        getinquiryList() {
            axios.get(`${baseUrl}/inquiry/inquiryList`,
                {
                    //参数列表
                    params: { pageNo: this.inquiryListPageNo, state: this.states, cate: "空运订单" },
                    //请求头配置  
                    headers: { 'authorization': sessionStorage.getItem("token") }
                }
            ).then(res => {
                console.log(res);
                if (res.data.success == false) {
                    let messages = res.data.message;
                    this.$message({
                        showClose: true,
                        message: messages,
                        type: 'error'
                    });
                    return false
                } else {
                    this.orderDetailList = res.data.result.records;

                    this.paging.total = res.data.result.total;
                }


            }).catch(err => {
                console.error(err);
            })
        },

        changeFous() {
            this.formData.pageNo = 1;
            startCityList().then(res => {
                // console.log(res.result);
                this.departureStationList = res.result.records;
            });
        },
        changeFous1() {
            this.formData1.pageNo = 1;
            endCityList().then(res => {
                // console.log(res.result);
                this.destinationListList = res.result.records;
            });
        },
        // #region //搜索框

        remoteMethod(value) { //
            // console.log("$$$", value);
            if (value) {
                startCityList({ name: value }).then((res) => {
                    this.departureStationList = res.result.records;
                })
            } else {
                startCityList().then(res => {
                    this.departureStationList = res.result.records;
                });
            }

        },
        remoteMethod1(value) {
            if (value) {
                endCityList({ name: value }).then((res) => {
                    this.destinationListList = res.result.records || '';
                })
            } else {
                endCityList().then(res => {
                    this.destinationListList = res.result.records;
                });
            }
        },
        // 懒加载
        loadmore() {
            this.formData.pageNo++;
            this.getList(this.formData);
        },
        loadmore1() {
            this.formData1.pageNo++;
            this.getList1(this.formData1);
        },
        // 下拉刷新
        getList(formData) {
            startCityList({ pageNo: formData.pageNo }).then((res) => {
                const _res = res.result.records;
                this.departureStationList = [...this.departureStationList, ..._res];
                // this.destinationListList = [...this.destinationListList, ..._res]
            })
        },
        getList1(formData) {
            endCityList({ pageNo: formData.pageNo }).then((res) => {
                const _res = res.result.records;
                // this.departureStationList = [...this.departureStationList, ..._res];
                this.destinationListList = [...this.destinationListList, ..._res]
            })
        },
        // #endregion

        num_Jia() { //加
            this.cabinetNum++
        },
        num_Jian() { //减
            if (this.cabinetNum > 0) {
                this.cabinetNum--;
            }
            if (this.cabinetNum <= 0) {
                this.cabinetNum = ''
            }
        },
        number_1s_Jia() { //件数加
            this.number_1s++
        },
        number_1s_Jian() { //件数减 
            if (this.number_1s > 0) {
                this.number_1s--;
            }
            if (this.number_1s <= 0) {
                this.number_1s = ''
            }
        },
        grossWeight_Jia() {
            //毛重加
            this.grossWeightNum++

        },
        grossWeight_Jian() {//毛重减
            if (this.grossWeightNum > 0) {
                this.grossWeightNum--;
            }
            if (this.grossWeightNum <= 0) {
                this.grossWeightNum = ''
            }
        },
        volume_Jia() { //体积加
            this.volumeNum++
        },
        volume_Jian() { //体积减
            if (this.volumeNum > 0) {
                this.volumeNum--;
            }
            if (this.volumeNum <= 0) {
                this.volumeNum = ''
            }
        },
        inquiry_Button() {  //点击询价
            let dayRangeDate1 = this.dayRangeDate[0];
            let dayRangeDate2 = this.dayRangeDate[1];
            let expectedTime1 = this.expectedTime[0];
            let expectedTime2 = this.expectedTime[1]
            var token = sessionStorage.getItem("token");
            // console.log(this.dayRangeDate);
            if (this.departureList == '' || this.destinationList == '') {
                this.$message({
                    showClose: true,
                    message: '请选择起运港和目的港',
                    type: 'warning'
                });
                return false
            } else if (this.radio == "Y" && this.toDoorAddress == "") {
                this.$message({
                    showClose: true,
                    message: '请输入到门地址',
                    type: 'warning'
                });
                return false
            } else if (!this.modeOfTradeList) {
                this.$message({
                    showClose: true,
                    message: '请选择贸易方式',
                    type: 'warning'
                });
                return false
            } else if (this.number_1s == "" || this.grossWeightNum == " " || this.volumeNum == "") {
                this.$message({
                    showClose: true,
                    message: '请输入件毛体',
                    type: 'warning'
                });
                return false
            } else if (this.dayRangeDate == '') {
                this.$message({
                    showClose: true,
                    message: '请选择时间',
                    type: 'warning'
                });
                return false
            } else {

                axios.post(`${baseUrl}/inquiry/addInquiry`, {
                    cate: "空运订单",
                    cupboard: "",  //柜型 海运、铁运专用
                    cupboardnum: '',  //柜子数量  海运、铁运专用
                    pkg: Number(this.number_1s),      //数量   int 大于0 长度10  空运专用
                    kgs: Number(this.grossWeightNum),  //重量  number 大于0  最大整数长度10+小数点3位 空运专用
                    cbm: Number(this.volumeNum),  // 体积 number 大于0  最大整数长度10+小数点3位 空运专用
                    tradeMode: this.modeOfTradeList, //贸易方式
                    departure: this.departureList,  //如cate==铁路，此处为城市  起运港
                    destination: this.destinationList, //目的港
                    packingtype: "LCL",//装箱类型  FCL、LCL二选一 空运装箱类型为LCL,
                    shippingSpace: "COC",  //仓位类型  COC 、SOC二选一
                    finalAddr: this.toDoorAddress, //到门地址
                    // pickUpTime: dayRangeDate1,  //装箱时间
                    pickUpTimeBeg: dayRangeDate1 || '',  //装箱时间
                    pickUpTimeEnd: dayRangeDate2 || '',
                    expectTimeBeg: expectedTime1 || '',   //期望达到时间范围
                    expectTimeEnd: expectedTime2 || '',
                    isDoor: this.radio, //是否到门
                    userNote: this.remarksValue || '',//备注

                }, {
                    headers: {
                        'authorization': token
                    }
                }).then(res => {
                    // console.log('询价成功', res.data)
                    if (res.data.success == true) {
                        this.handleXunJia = true;
                        this.inquiryList = res.data.result;
                        this.orderDetailList.push(res.data.result);
                        console.log('this.inquiryList', this.inquiryList);

                        this.containerTypeList = this.cabinetNum = this.modeOfTradeList = this.departureList = this.destinationList = this.toDoorAddress = this.dayRangeDate = this.expectedTime = this.remarksValue = "";
                    } else if (res.data.success == false) {
                        let messages = res.data.message
                        this.$message({
                            showClose: true,
                            message: messages,
                            type: 'error'
                        });
                        return false
                    }

                    // console.log(this.orderDetailList.length);
                }).catch(err => {
                    console.error(err);
                })
            }
        },
        placeAnOrderButton(row) {  //立即下单
            var timestamp1 = new Date(row.endTime).getTime();//结束时间
            var timestamp2 = new Date().getTime();//现在时间
            if (timestamp1 - timestamp2 < 0) {
                this.$message({
                    showClose: true,
                    message: '您的询价已过期，请您重新询价',
                    type: 'error'
                });
                return false
            } else {
                var token = sessionStorage.getItem("token");
                if (token == null || this.token == "") {
                    this.$message({
                        message: '您还没有登录',
                        type: 'success'
                    });
                    return false
                } else if (row.cate === "海运订单") {
                    this.$router.push({
                        path: "/EnquiryCabinet/BookingSpaceoceanShipping",
                        query: {
                            id: row.id
                        }
                    })
                } else {
                    this.$router.push({
                        path: "/EnquiryCabinet/BookingSpace",
                        query: {
                            id: row.id
                        }
                    })
                }
            }

        },
        handleSizeChange(val) {  //分页
            // console.log(val);
            var token = sessionStorage.getItem("token");
            axios.get(`${baseUrl}/inquiry/inquiryList`,
                {
                    //参数列表
                    params: {
                        cate: "空运订单",
                        pageNo: val,
                        state: this.states
                    },
                    //请求头配置  
                    headers: { 'authorization': token }
                }
            ).then(res => {
                this.orderDetailList.push(res.data.result);
                // console.log("详情", res.data.result)
                this.orderDetailList = res.data.result.records
            }).catch(err => {
                console.error(err);
            })
        },
        handleClick(row) {
            // console.log(row);
        },
        handleDetailOrder(row) {  //详情
            console.log('详情', row);
            this.$router.push({
                name: "InquiryDetails",
                params: {
                    detailList: row
                }
            })
        },
        cancelTheOrder(row) {
            var token = sessionStorage.getItem("token")
            console.log("撤单", row.id);
            axios.delete(`${baseUrl}/inquiry/deleteInquiry`, {
                params: {
                    id: row.id,
                },
                headers: {
                    'authorization': token
                }
            })
                .then(res => {
                    console.log(res);
                    if (res.data.success == true) {
                        this.$message({
                            showClose: true,
                            message: '删除成功',
                            type: 'success'
                        });
                        this.getinquiryList()

                    } else {
                        let message = res.data.message;
                        this.$message({
                            showClose: true,
                            message: message,
                            type: 'success',
                        });
                        return false
                    }
                })
                .catch(err => {
                    console.error(err);
                })
        },
        handleClose() { //点击关闭
            this.handleXunJia = false
        },
    },
}
</script>

<style lang="scss" scoped>
// 下拉菜单
.el-select {
    width: 276px;
    height: 56px;

    ::v-deep .el-input__inner {
        width: 276px;
        height: 56px;
        font-size: 16px;
        color: #2B2B2B;
        border: 1px solid #999999;
    }
}

.el-select .el-input.is-focus .el-input__inner {
    border-color: 0;
}

::v-deep .el-icon-arrow-up:before {
    content: "\e78f";
}

.inquiry {
    width: 100%;
    margin: -100px auto 0;

    // border: 1px solid;
    .inquiryTitle {
        margin: 0 auto 24px;
        width: 1500px;
        font-size: 16px;
        font-weight: 800;
        color: #FFFFFF;
    }

    .inquiry_Top {
        // display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        width: 1500px;
        margin: 0 auto;
        // height: 265px;
        z-index: 10;
        padding: 32px 24px 32px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;

        .inquiry_Smale_1 {
            width: 100%;
            height: 56px;
            display: flex;
            justify-content: space-between;
        }

        // 起运巷
        .departure {
            width: 276px;
            height: 56px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
        }

        // 目的巷
        .destination {
            width: 276px;
            height: 56px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
        }

        .dayRange {
            width: 276px;
            height: 56px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            // margin-left: 16px;

            .el-date-editor,
            ::v-deep .el-input__inner {
                width: 276px;
                height: 56px;
                border-color: #999999;
            }
        }

        // 重量
        .weightS {
            display: flex;
            height: 56px;
            line-height: 56px;

            .weight_Left {
                padding-left: 10px;
                margin-right: 10px;
            }

            .number_1s {
                margin-left: 16px;
            }

            .weightSInput {
                width: 150px;
                height: 56px;
                position: relative;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #999999;
                // overflow: hidden;
                margin-left: 15px;

                input {
                    width: 100%;
                    height: 49px;
                    border: 0;
                    outline: none;
                    padding-left: 16px;
                    font-weight: 400;
                    font-size: 16px;
                    color: #2B2B2B;
                }

                input::placeholder {

                    color: #c0c4cc;
                }

                .num_Icon {
                    display: flex;
                    flex-wrap: wrap;
                    position: absolute;

                    right: 14px;
                    top: 0;
                    width: 10px;

                    i {
                        height: 5px;
                        margin-top: 8px;
                        color: #999999;
                    }
                }

                .placeholderS {
                    position: absolute;
                    left: 16px;
                    top: -12px;
                    height: 18px;
                    font-size: 16px;
                    color: #999999;
                    line-height: 18px;
                    text-align: center;
                    padding: 0 5px;
                    background-color: white;
                }
            }
        }

        .containerType {
            width: 276px;
            height: 56px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
        }

        .inquiry_Button {
            margin-top: 14px;

            .div1 {
                display: flex;
                height: 30px;

                .isdaomen {
                    height: 16px;
                    line-height: 16px;

                    span {

                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 16px;
                    }

                    input {
                        width: 16px;
                        height: 16px;
                    }

                    .label_1 {
                        margin-left: 27px;
                    }

                    .label_2 {
                        margin-left: 16px;
                    }
                }

                .dizhi {
                    span {
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px
                    }

                    input {
                        width: 448px;
                        border: 0;
                        outline: none;
                        margin-left: 8px;
                        font-size: 16px;
                        border-bottom: 1px solid #999999;
                        padding-bottom: 9px;
                    }
                }
            }

            .div2 {
                display: flex;
                justify-content: space-between;
                margin-top: 32px;

                .notes {
                    width: 678px;
                    // height: 50px;
                    background: #FFFFFF;
                    border-radius: 4px 4px 4px 4px;
                }

                .btns {
                    width: 120px;
                    height: 56px;
                    background: #1890FF;
                    box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
                    border-radius: 4px 4px 4px 4px;
                    text-align: center;
                    line-height: 56px;
                    color: #FFFFFF;
                    cursor: pointer;

                    span {
                        color: #FFFFFF;
                    }

                    i {
                        margin-right: 8px;
                    }
                }
            }

            // display: flex;
            // justify-content: space-between;
            // height: 56px;
            // align-items: center;
            // width: 100%;
            // // background: #ff3467;
            // margin-top: 9px;

            // div:nth-child(1) {
            //     height: 16px;
            //     line-height: 16px;

            //     span {

            //         font-size: 16px;
            //         font-weight: 400;
            //         color: #333333;
            //         line-height: 16px;
            //     }

            //     input {
            //         width: 16px;
            //         height: 16px;
            //     }

            //     .label_1 {
            //         margin-left: 27px;
            //     }

            //     .label_2 {
            //         margin-left: 16px;
            //     }
            // }

            // div:nth-child(2) {
            //     span {
            //         font-size: 16px;
            //         font-weight: 400;
            //         color: #333333;
            //         line-height: 24px
            //     }

            //     input {
            //         width: 448px;
            //         border: 0;
            //         outline: none;
            //         margin-left: 8px;
            //         font-size: 16px;
            //         border-bottom: 1px solid #999999;
            //         padding-bottom: 9px;
            //     }
            // }

            // div:nth-child(3) {
            //     width: 160px;
            //     height: 56px;
            //     background: #1890FF;
            //     box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            //     border-radius: 4px 4px 4px 4px;
            //     text-align: center;
            //     line-height: 56px;
            //     color: #FFFFFF;
            //     cursor: pointer;

            //     i {
            //         margin-right: 8px;
            //     }
            // }
        }
    }

    .inquiry_Smale_2 {
        margin-top: 24px;
        display: flex;
        height: 56px;

        .numberOfCabinets {
            position: relative;
            width: 276px;
            height: 56px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #999999;
            overflow: hidden;

            input {
                width: 100%;
                height: 100%;
                border: 0;
                outline: none;
                padding-left: 16px;
                font-weight: 400;
                font-size: 16px;
                color: #2B2B2B;
            }

            input::placeholder {

                color: #c0c4cc;
            }

            .num_Icon {
                display: flex;
                flex-wrap: wrap;
                position: absolute;

                right: 14px;
                top: 0;
                width: 10px;

                i {
                    height: 5px;
                    margin-top: 8px;
                    color: #999999;
                }
            }
        }

        .modeOfTrade {
            margin-left: 16px;
        }

        .shippingSpace {
            margin-left: 16px;
        }
    }
}

.inquiry_ButtomS {
    position: relative;
    width: 1500px;
    height: auto;
    background: #FFFFFF;
    margin: 24px auto 0;
    border-radius: 8px 8px 8px 8px;

    ::v-deep .nana {
        text-align: center;
    }

    .placeAnOrder_Title {
        overflow: hidden;
        overflow-x: auto;
        overflow-X: scroll;
        white-space: nowrap;


        ul {
            display: flex;
        }

        span {
            width: 200px;
            display: inline-block;
            text-align: center;
        }



    }

    .placeAnOrderImmediately1 {
        width: 72px;
        height: 24px;
        background: #999999;
        box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        // line-height: 24px;
        color: #FFFFFF;
        font-size: 12px;
        padding: 0;
    }

    .placeAnOrderImmediately {
        width: 72px;
        height: 24px;
        background: #1890FF;
        box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        // line-height: 24px;
        color: #FFFFFF;
        font-size: 12px;
        padding: 0;
    }

    .detailOrder {
        width: 48px;
        height: 24px;
        background: #4CAF50;
        box-shadow: 0px 3px 6px 1px rgba(76, 175, 80, 0.3);
        border-radius: 4px 4px 4px 4px;
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        padding: 0;
    }

    .cancelTheOrder {
        width: 48px;
        height: 24px;
        background: #D4252C;
        box-shadow: 0px 3px 6px 1px rgba(212, 37, 44, 0.3);
        border-radius: 4px 4px 4px 4px;
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        padding: 0;
        // margin-left: 24px;
    }

    .paging {
        display: flex;
        justify-content: flex-end;
        position: relative;
        float: right;
        margin-top: 32px;
    }
}

.xunjia {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000;

    .xunjiaBox {
        position: relative;
        width: 1500px;
        height: 449px;
        margin: 303px auto;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        padding: 32px;

        .xjTop {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .img {
                display: flex;
                // width: 53px;
                height: 63px;
                // background: #2F9BFF;
                border-radius: 10px 10px 10px 10px;
                overflow: hidden;

                img {
                    width: 74px;
                    border-radius: 10px 10px 10px 10px;
                    height: 63px;
                }
            }

            .inquirys {
                margin-left: 24px;

                p:nth-child(1) {
                    font-size: 18px;
                    font-weight: bold;
                    color: #000000;
                }

                p:nth-child(2) {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 12px;
                }
            }

            .xdDate {
                p:nth-child(1) {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                }

                p:nth-child(2) {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 15px;
                }
            }

            .orderNumber {
                font-size: 14px;
                font-weight: 400;
                color: #999999;

                p:nth-child(2) {
                    margin-top: 15px;
                    color: #333333;
                }
            }

            .orderType {
                font-size: 14px;
                font-weight: 400;
                color: #999999;

                p:nth-child(2) {
                    margin-top: 15px;
                    color: #333333;
                }
            }
        }

        .tips {
            // width: 1136px;
            height: 40px;
            background: #FFF2E5;
            margin-top: 25px;
            padding-left: 40px;

            p {
                font-size: 12px;
                font-weight: 400;
                color: #D4252C;
                line-height: 40px;
            }
        }

        .xjButtom {
            // width: 1136px;
            height: 175px;
            border: 1px solid #999999;
            margin-top: 24px;
            padding: 32px 0;

            ul {
                display: flex;
                justify-content: space-around;

                li {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                }

                .lis_1 {
                    display: flex;

                    .startingPoint {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        height: 112px;

                        .radius_1,
                        .radius_5 {
                            width: 36px;
                            height: 36px;
                            background: #1890FF;
                            border-radius: 50%;
                            font-size: 12px;
                            font-weight: 400;
                            color: #FFFFFF;
                            text-align: center;
                            line-height: 36px;
                        }

                        .radius_5 {
                            background: #FFC13B;
                        }

                        .radius_2,
                        .radius_3,
                        .radius_4 {
                            width: 4px;
                            height: 4px;
                            background: #999999;
                            border-radius: 50%;
                        }
                    }

                    .address {
                        margin-left: 24px;
                        height: 112px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 11px 0;
                        font-size: 14px;
                        font-weight: bold;
                        color: #333333;
                    }
                }

                .lis_2 {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;

                    p:nth-child(2) {
                        margin-top: 5px;
                    }
                }
            }
        }

        .xjBth {
            position: absolute;
            bottom: 32px;
            right: 32px;
            display: flex;

            .close {
                width: 100px;
                height: 34px;
                background: #F4F4F4;
                border-radius: 30px 30px 30px 30px;
                text-align: center;
                line-height: 34px;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                margin-right: 32px;
                cursor: pointer;
            }

            .see {
                width: 100px;
                height: 34px;
                background: #1890FF;
                border-radius: 30px 30px 30px 30px;
                text-align: center;
                line-height: 34px;
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
                cursor: pointer;
            }
        }
    }

}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 649px;
    //     /*滚动条宽度*/
    height: 8px;
    //     /*滚动条高度*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 0px;
    /*滚动条的背景区域的圆角*/

    background: rgba(153, 153, 153, 0.2);
    /*滚动条的背景颜色*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    /*滚动条的圆角*/

    background: #999999;
    /*滚动条的背景颜色*/
}

::v-deep .el-table tr:nth-child(2n-1) {
    background: #F4F4F4;
}

::v-deep .el-table tr {
    height: 84px;
}

::v-deep .el-table__cell .cell {
    // height: 24px;
    line-height: 24px;
    font-size: 14px;
}

::v-deep .el-table .cell {
    white-space: nowrap;
    width: fit-content;
}

::v-deep .el-table_1_column_11 {
    text-align: center;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
    width: 28px;
    height: 28px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    color: #666666;
    border: 1px solid #999999;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px; //修改选中后的样式
    color: #1890FF;
    border: 1px solid #1890FF;
}

::v-deep .el-pager .active {
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    color: #1890FF;
}
</style>